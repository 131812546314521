<template>
  <div>
    <a-auto-complete
      :value="value"
      :placeholder="placeholder"
      :size="size"
      allowClear
      :data-source="dataItems"
      :filterOption="filterOption"
      @select="onSelect"
      @change="changeValue"
    />
  </div>
</template>

<script>
import { userOptionList } from "@/apis/option";

export default {
  props: ["value", "size", "placeholder"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
    };
  },
  methods: {
    onSelect(value) {
      this.$emit("change", value);
    },
    changeValue(value) {
      this.$emit("change", value);
    },
    filterOption(inputValue, option) {
      return option.key.includes(inputValue);
    },
  },
  mounted() {
    userOptionList().then((data) => (this.dataItems = [...new Set(data.map((item) => item.unit_name))]));
  },
};
</script>

<style scoped></style>
